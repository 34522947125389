<template>
  <div class="nav">
    <el-menu
      class="el-menu-demo"
      background-color="#ae703e"
      text-color="#fff"
      active-text-color="#fff"
      mode="horizontal"
    >
      <el-menu-item index="/" @click="changePage('/', '/')">首页</el-menu-item>
      <el-menu-item
        index="Aggregateretrieval"
        @click="changePage('Aggregateretrieval', 'Aggregateretrieval')"
        >书目检索</el-menu-item
      >
      <el-menu-item
        index="CategoryBrowsing"
        @click="changePage('CategoryBrowsing', 'CategoryBrowsing')"
        >分类浏览</el-menu-item
      >
      <el-menu-item index="HotCommend" @click="changePage('HotCommend', 'HotCommend')"
        >热门推荐</el-menu-item
      >
      <el-menu-item index="NewBook" @click="changePage('NewBook', 'NewBook')"
        >新书通报</el-menu-item
      >
      <!-- <el-menu-item index="Cooperation" @click="changePage('list-text', 'Cooperation')"
        >我的图书馆</el-menu-item
      > -->
      <el-submenu index="Retrievalpersonal">
        <template slot="title">我的图书馆</template>
        <el-menu-item
          index="Retrievalpersonal"
          @click="changePage('Retrievalpersonal', 'Retrievalpersonal')"
          >个人中心</el-menu-item
        >
        <el-menu-item
          index="Retrievalsetting"
          @click="changePage('Retrievalsetting', 'Retrievalsetting')"
          >设置</el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {};
  },
  methods: {
    changePagetype(path, fromPath, id, title, type, resourcesUrl) {
      if (type == 1) {
        window.open("https://" + resourcesUrl);
      } else if (this.$route.path !== path) {
        this.$router.push({
          path,
          query: { id, title, fromPath },
        });
      }
    },
    changePage(path, fromPath, id, title) {
      if (this.$route.path !== path) {
        this.$router.push({
          path,
          query: { id, title, fromPath },
        });
      }
    },
  },
  created() {
    var result = window.location.pathname.substr(1, window.location.pathname.length);
    this.fromPath = result;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}
.nav .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border-bottom: none !important;
}
.nav {
  width: 100%;
  height: auto;
  background-color: #ae703e;
  box-shadow: 0px 3px 6px 0px rgba(47, 69, 59, 0.66);
  
}
.el-menu.el-menu--horizontal {
  width: 1200px;
  background-color: #ae703e;
  margin: 0px auto;
  color: #333;
  height: 50px;
}
.el-carousel--horizontal {
  width: 100% !important;
}
.el-menu-item {
  text-align: center;
  font-size: 15px;
  width: 135px;
}
/* .el-menu--horizontal > .el-submenu .el-submenu__title {
  text-align: center;
  font-size: 15px;
  width: 95px;
} */
.el-menu--horizontal > .el-menu-item.is-active {
  border: none;
  border-bottom: 3px solid #fff;
  color: #fff;
  background-color: #ae703e;
  /* width: 95px; */
  height: 50px;
  line-height: 50px;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  color: #fff;
}
.el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
  color: #fff;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-submenu {
  width: 130px;
  height: 50px;
}
</style>
